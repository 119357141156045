@import "../../Constants/Variables"
.button
  display: flex
  height: 100%
  width: 100%
  flex-direction: column
  justify-content: center
  align-items: center
  background: $secondary
  color: $title
  text-align: center
  font-family: Montserrat, sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 600
  line-height: normal
  border-radius: 8px
  border: none
  &-wrapper
    width: 40%
    height: 48px
    padding: 4px
    box-sizing: border-box
    cursor: pointer
    border-radius: 10px
    border: 2px dashed  $borderColorMainBlockBtn
    &:hover
      border: 2px solid  $borderColorMainBlockBtn

@media (max-width: 720px)
  .button
    &-wrapper
      margin-top: 12px
      width: 100%
