@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.wrap {
  background-color: #FFA700;
  background-size: contain;
  background-repeat: no-repeat;
}
.wrap_black {
  background: #001219;
}

.box {
  padding: 64px 0;
  display: flex;
  flex-direction: column;
}
.box_subTitle {
  overflow: hidden;
  color: #0A090D;
  text-overflow: ellipsis;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
.box_title {
  color: #0A090D;
  font-family: "Montserrat", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4px;
}
.box_title__white {
  color: #FFF;
}
.box_form {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 12px;
}
.box_form__box {
  display: flex;
  flex-direction: column;
  width: 39%;
  position: relative;
}
.box_form__box_lbl {
  color: #0A090D;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
}
.box_form__box_lbl__white {
  color: #FFF;
}
.box_form__box_inp {
  border-radius: var(--radius-md, 8px);
  border: 1px solid #D0D5DD;
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  height: 48px;
  box-sizing: border-box;
  padding: 10px var(--spacing-lg, 12px);
  outline: none;
  display: flex;
  align-items: center;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
}
.box_form__box_inp::placeholder {
  color: #959595;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.box_form__box_inp > :nth-child(2) {
  font-family: "Gilroy", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  width: 100% !important;
}
.box_form__btnBox {
  border-radius: 10px;
  border: 2px dashed #FFE0BD;
  box-sizing: border-box;
  padding: 4px;
  height: 48px;
  width: 180px;
  display: flex;
  align-items: center;
}
.box_form__btnBox:hover {
  border: 2px solid #FFE0BD;
}
.box_form__btnBox_second {
  border: 2px dashed #FFE0BD;
}
.box_form__btnBox_second:hover {
  border: 2px solid #FFE0BD;
}
.box_form__btnBox_btn {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  background: #0A090D;
  border: none;
  color: #FF9A00;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.box_form__btnBox_btn__second {
  background: #FFA700;
  color: #0A090D;
}

@media (max-width: 1200px) {
  .box_form__box {
    display: flex;
    flex-direction: column;
    width: 32%;
  }
  .box_form__box_lbl {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 6px;
  }
  .box_form__box_inp {
    border-radius: var(--radius-md, 8px);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    height: 48px;
    box-sizing: border-box;
    padding: 10px var(--spacing-lg, 12px);
  }
  .box_form__box_inp::placeholder {
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .box_form__btnBox {
    border-radius: 10px;
    box-sizing: border-box;
    padding: 4px;
    height: 48px;
    width: 237px;
    display: flex;
    align-items: center;
  }
  .box_form__btnBox_btn {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: none;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
@media (max-width: 720px) {
  .box {
    text-align: center;
  }
  .box_form {
    flex-direction: column;
    align-items: stretch;
    gap: 21px;
  }
  .box_form__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .box_form__box_lbl {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 6px;
  }
  .box_form__box_inp {
    width: 100%;
    border-radius: var(--radius-md, 8px);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    height: 44px;
    box-sizing: border-box;
    padding: 10px var(--spacing-lg, 12px);
  }
  .box_form__box_inp::placeholder {
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .box_form__btnBox {
    margin-top: 10px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 4px;
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .box_form__btnBox_btn {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: none;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

