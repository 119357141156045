@import '../../Constants/Variables.sass';

.offers {
  font-family: 'Montserrat', sans-serif;
  padding: 60px 0;

  &__title {
    @include title;
    text-align: center;
    margin-bottom: 40px;
  }

  &__cards {
    display: grid;

    &_two {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &_more{
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    gap: 91px;
    row-gap: 40px;

    @media (max-width: 1440px) {
      gap: 45px;
    }
  }

  &__card {
    width: 100%;
    border-radius: 10px;
    box-shadow: 6px 10px 32.29999923706055px 0px #0000001a;
  }
}

.offersForm {
  background-color: $black;
  color: white;
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 24px;

  &__title {
    font-size: 30px;
    font-weight: 700;
    line-height: 36.57px;
    text-align: center;
    margin-bottom: 4px;

    margin-bottom: 5px;
  }

  &__subTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: center;
    
  }
    button {
      //all: unset;
      width: 100%;
      height: 100%;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;
      color: $title;

      background-color: $secondary;
      //padding: 12px 0;
      border: none;
      border-radius: 8px;

      position: relative;
    }

  &__inputsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &_box {
      display: flex;
      flex-direction: column;
      gap: 6px;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      &__inp {
        border-radius: var(--radius-md, 8px);
        border: 1px solid $formBorderColor;
        background: $white;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        height: 44px;
        width: 100%;
        box-sizing: border-box;
        padding: 10px var(--spacing-lg, 12px);
        outline: none;
        display: flex;
        align-items: center;
        font-family: 'Gilroy', sans-serif;
        font-size: 14px;
        &::placeholder {
          color: $formPlaceholderColor;
          font-family: 'Gilroy', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          & > :nth-child(2) {
            font-family: 'Gilroy', sans-serif !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 24px !important;
            width: 100% !important;
          }
        }
      }
    }
  }

  &__btnContainer {
    padding: 4px;
    border: 2px dashed $secondary;
    border-radius: 10px;
    box-sizing: border-box;
    height: 48px;

    &:hover {
      border-style: solid;
    }
  }
}

.offersOffer {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 160px auto;

  &__badge {
    width: 82px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    align-items: center;
    justify-content: center;
    color: $title;

    border-radius: 5px;
    border: 1px solid #eaecf0;

    position: absolute;
    top: 5px;
    left: 5px;
    background-color: white;
    display: flex;
    gap: 5px;
  }

  &__circle {
    width: 6px;
    height: 6px;
    background-color: $primary;
    border-radius: 50%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__info {
    padding: 15px 0;
    max-width: 238px;
    justify-self: center;

    display: flex;
    flex-direction: column;
    justify-content:space-between;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: $title;

    margin-bottom: 12px;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: $text;

    margin-bottom: 15px;
  }

  &__price {
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: $text;

    span {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;

      color: $primary;
      margin-left: 12px;
    }
  }
}

@media (max-width: 1200px) {
  .offers {
    font-family: Montserrat;
    padding: 60px 0;

    &__title {
      @include title;
      text-align: center;
      margin-bottom: 40px;
    }

    &__cards {
      display: grid;
      column-gap: 20px;
      row-gap: 68px;
      height: auto;

      &_two {
        grid-template-columns: 1fr 1fr;
      }
      &_three {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &__card {
      width: 100%;
      border-radius: 10px;
      box-shadow: 6px 10px 32.29999923706055px 0px #0000001a;
    }
  }

  .offersForm {
    height: 336px;

    padding: 24px 12px;

    &__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: center;

      margin-bottom: 4px;
    }

    &__subTitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__input {
      position: relative;

      input {
        height: 44px;
        border-radius: 8px;
        border: 1px solid $background;
        padding: 10px 12px;
        color: $title;

        &:focus {
          outline: none;
        }
      }

      &::before {
        content: '';
        position: absolute;
        background-color: transparent;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        right: 7px;
        top: 7px;
      }

      &_valid {
        &::before {
          background-color: rgb(4, 195, 4);
        }
      }

      &_invalid {
        &::before {
          background-color: red;
        }
      }
    }

    &__inputsContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &_box {
        display: flex;
        flex-direction: column;
        gap: 6px;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        &__inp {
          border-radius: var(--radius-md, 8px);
          border: 1px solid $formBorderColor;
          background: $white;
          box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
          height: 44px;
          width: 100%;
          box-sizing: border-box;
          padding: 10px var(--spacing-lg, 12px);
          outline: none;
          display: flex;
          align-items: center;
          font-family: 'Gilroy', sans-serif;
          font-size: 14px;
          &::placeholder {
            color: $formPlaceholderColor;
            font-family: 'Gilroy', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            & > :nth-child(2) {
              font-family: 'Gilroy', sans-serif !important;
              font-size: 14px !important;
              font-style: normal !important;
              font-weight: 400 !important;
              line-height: 24px !important;
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  .offersOffer {
    position: relative;
    height: 340px;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 160px auto;

    &__badge {
      width: 82px;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0em;
      align-items: center;
      justify-content: center;
      color: $title;

      border-radius: 5px;
      border: 1px solid #eaecf0;

      position: absolute;
      top: 5px;
      left: 5px;
      background-color: white;
      display: flex;
      gap: 5px;
    }

    &__circle {
      width: 6px;
      height: 6px;
      background-color: $primary;
      border-radius: 50%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &__info {
      padding: 15px 0;
      max-width: 238px;
      justify-self: center;
    }

    &__title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: $title;

      margin-bottom: 12px;
    }

    &__text {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;
      color: $text;

      margin-bottom: 15px;
    }

    &__price {
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: $text;

      span {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;

        color: $primary;
        margin-left: 12px;
      }
    }
  }
}

@media (max-width: 720px) {
  .offers {
    font-family: Montserrat;
    padding: 20px 0;

    &__title {
      @include title;
      text-align: center;
      margin-bottom: 40px;
    }

    &__cards {
      display: grid;
      column-gap: 20px;
      row-gap: 40px;
      height: auto;

      &_two {
        grid-template-columns: 1fr;
      }
      &_three {
        grid-template-columns: 1fr;
      }
    }

    &__card {
      width: 100%;
      border-radius: 10px;
      box-shadow: 6px 10px 32.29999923706055px 0px #0000001a;
    }
  }

  .offersForm {
    grid-row: 6;
    grid-column: auto;
    height: 336px;

    padding: 24px 12px;

    &__title {
      text-align: center;

      margin-bottom: 4px;
    }

    &__subTitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__input {
      position: relative;

      input {
        height: 44px;
        border-radius: 8px;
        border: 1px solid $background;
        padding: 10px 12px;
        color: $title;

        &:focus {
          outline: none;
        }
      }

      &::before {
        content: '';
        position: absolute;
        background-color: transparent;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        right: 7px;
        top: 7px;
      }

      &_valid {
        &::before {
          background-color: rgb(4, 195, 4);
        }
      }

      &_invalid {
        &::before {
          background-color: red;
        }
      }
    }

    &__inputsContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &_box {
        display: flex;
        flex-direction: column;
        gap: 6px;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        &__inp {
          border-radius: var(--radius-md, 8px);
          border: 1px solid $formBorderColor;
          background: $white;
          box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
          height: 44px;
          width: 100%;
          box-sizing: border-box;
          padding: 10px var(--spacing-lg, 12px);
          outline: none;
          display: flex;
          align-items: center;
          font-family: 'Gilroy', sans-serif;
          font-size: 14px;
          &::placeholder {
            color: $formPlaceholderColor;
            font-family: 'Gilroy', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            & > :nth-child(2) {
              font-family: 'Gilroy', sans-serif !important;
              font-size: 14px !important;
              font-style: normal !important;
              font-weight: 400 !important;
              line-height: 24px !important;
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  .offersOffer {
    position: relative;
    height: 340px;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 160px auto;

    &__badge {
      width: 82px;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0em;
      align-items: center;
      justify-content: center;
      color: $title;

      border-radius: 5px;
      border: 1px solid #eaecf0;

      position: absolute;
      top: 5px;
      left: 5px;
      background-color: white;
      display: flex;
      gap: 5px;
    }

    &__circle {
      width: 6px;
      height: 6px;
      background-color: $primary;
      border-radius: 50%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &__info {
      padding: 15px 0;
      max-width: 238px;
      justify-self: center;
    }

    &__title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: $title;

      margin-bottom: 12px;
    }

    &__text {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;
      color: $text;

      margin-bottom: 15px;
    }

    &__price {
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: $text;

      span {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;

        color: $primary;
        margin-left: 12px;
      }
    }
  }
}
