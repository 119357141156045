.copyright
  background-color: #F8F8F8
  padding-top: 24px
  padding-bottom: 24px

.footer_copyright
  display: flex
  justify-content: center


.copyright_dots
  display: block
  color: #959595
  margin-right: 15px
  margin-left: 15px

.copyright_text
  color: #959595
  font-family: Montserrat, sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 600
  line-height: 24px
  letter-spacing: 0.2px

.copyright_text_left
  color: #959595
  font-family: Montserrat, sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 600
  line-height: 24px
  letter-spacing: 0.2px

.copyright_link
  text-decoration: none

@media (max-width: 720px)
  .footer_copyright
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

  .copyright_dots
    display: none

  .copyright_text
    color: #959595
    font-family: Montserrat, sans-serif
    font-size: 14px
    font-style: normal
    font-weight: 600

  .copyright_text_left
    font-weight: 400
