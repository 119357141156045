@import "../../../Constants/Variables"
.wrapHidden
  animation: slideOut 0.4s ease-out
  transform: translateY(-100%)
.wrapShow
  animation: slideIn 0.4s ease-in
.wrap
  position: sticky
  top: 0
  background: $white
  z-index: 1000
.container
  display: flex
  align-items: center
  justify-content: space-between
  padding: 15px 0
  &_logo
    width: 88.137px
  &_linksWrap
    display: flex
    align-items: center
    margin: 0
    padding: 0
    gap: 44px
    &__link
      color: $headerLinkColor
      font-family: 'Montserrat', sans-serif
      font-size: 14px
      font-style: normal
      font-weight: 600
      line-height: normal
      cursor: pointer
  &_btn
    display: flex
    height: 40px
    padding: 14px 28px
    justify-content: center
    align-items: center
    color: $white
    text-align: center
    font-family: 'Montserrat', sans-serif
    font-size: 16px
    font-style: normal
    font-weight: 600
    line-height: normal
    border-radius: 8px
    background: $headerBtn
    border: none
    &:hover
      background: $headerBtnHover


@keyframes slideIn
  0%
    transform: translateY(-100%)
  100%
    transform: translateY(0)
@keyframes slideOut
  0%
    transform: translateY(0)
  100%
    transform: translateY(-100%)


@media (max-width: 1200px)
  .container
    display: flex
    align-items: center
    justify-content: space-between
    padding: 23px 0
    &_logo
      width: 98.137px
    &_linksWrap
      display: flex
      align-items: center
      margin: 0
      padding: 0
      gap: 44px
      &__link
        color: $headerLinkColor
        font-family: 'Montserrat', sans-serif
        font-size: 14px
        font-style: normal
        font-weight: 600
        line-height: normal
        cursor: pointer
    &_btn
      display: none
@media (max-width: 720px)
  .container
    display: none
