@import '../../Constants/Variables.sass';

.steps {
  padding: 60px 0;
  font-family: Montserrat;

  &__mobile {
    display: none;
  }
  
  &__title {
    @include title;
    text-align: center;
  }

  svg {
    width: 98%;
    circle {
      fill: $primary;
    }
    stop {
      stop-color: $background;
    }
    stop:nth-child(3n+3) {
      stop-color: $primary;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__top, &__bottom {
    padding: 32px;
    display: flex;
    gap: 55px;
  }

  &__top {
    padding-left: 20px;
  }

  &__bottom {
    padding-left: 219px;
    gap: 86px;
  }

  &__num {
    font-family: Montserrat;
    font-size: 50px;
    font-weight: 700;
    line-height: 61px;
    letter-spacing: 0em;
    text-align: center;
    color: #2E2A44
  }

  &__visual {
    justify-self: center;
    display: flex;
    gap: 5px;
    height: 77px;
    img {
      align-self: end;
    }
  }

  &__step {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: $title;

    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;

    &_bottom {
      .steps__visual {
        order: 99;
        img {
          align-self: flex-start;
        }

        div {
          align-self: flex-end;
        }
      }
    }
  }

}
.steps__step_top{
  background-image: url("../../Assets/Icons/stepsTop.webp");
  border-radius: 50px;
  background-size: cover;
  width: 310px;
  height: 200px;
  background-position: center;
  padding: 32px 32px 54px 32px;
}
.steps__step_bottom{
  background-image: url("../../Assets/Icons/stepsBottom.webp");
  border-radius: 50px;
  background-size: cover;
  width: 310px;
  height: 200px;
  background-position: center;
  padding: 54px 32px 32px  32px;
}
@media (max-width: 1440px) {
  .steps {
    padding: 60px 0;
    font-family: Montserrat;
    
    &__title {
      @include title;
      text-align: center;
    }
  
    svg {

    }
  
    &__grid {
      display: grid;
      grid-template-columns: 1fr;
    }
  
    &__top, &__bottom {
      padding: 32px;
      display: flex;
      gap: 60px;
    }
  
    &__bottom {
      padding-left: 14%;
      padding-top: 26px;
      gap: 9%;
    }
  
    &__num {
      font-size: 50px;
      font-weight: 700;
      line-height: 61px;
      letter-spacing: 0em;
      text-align: center;
      
      text-align: center;
    }
  
    &__visual {
      justify-self: center;
      display: flex;
      gap: 5px;
      height: 77px;
      img {
        align-self: end;
        width: 49px;
      }
    }
  
    &__step {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      background-size: 100% 100%;
      color: $title;
  
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
  
      &_bottom {
        .steps__visual {
          order: 99;
          img {
            align-self: flex-start;
          }
  
          div {
            align-self: flex-end;
          }
        }
      }
    }
  
  }
  
}

@media (max-width: 1200px) {
  .steps {
    padding: 60px 0;
    font-family: Montserrat;
    
    &__title {
      @include title;
      text-align: center;
      margin-bottom: 59px;
    }
  
    &__grid {
      display: grid;
      grid-template-columns: 1fr;
    }
  
    &__top, &__bottom {
      padding: 0px;
      display: flex;
      gap: 30px;
    }
  
    &__bottom {
      padding-left: 96px;
      padding-top: 0px;

      .steps__step {
        min-width: auto;
        max-width: auto;
      }
    }
  
    &__num {
      font-size: 32px;
      font-weight: 700;
      line-height: 39px;
      letter-spacing: 0em;
      text-align: center;
      
      text-align: center;
      
      text-align: center;
    }
  
    &__visual {
      justify-self: center;
      display: flex;
      gap: 5px;
      height: 48px;
      img {
        align-self: end;
        width: 30px;
      }
    }
  
    &__step {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;

      color: $title;
  
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
  
      &_bottom {
        .steps__visual {
          order: 99;
          img {
            align-self: flex-start;
          }
  
          div {
            align-self: flex-end;
          }
        }
      }
    }
  }
  .steps__step_top{
    background-image: url("../../Assets/Icons/stepsTop.webp");
    border-radius: 0;
    background-size: 100% 100%;
    width: 208px;
    height: 151px;
    background-position: center;
    padding: 20px 20px 54px 20px;
  }
  .steps__step_bottom{
    background-image: url("../../Assets/Icons/stepsBottom.webp");
    border-radius: 0;
    background-size: 100% 100%;
    width: 208px;
    height: 151px;
    background-position: center;
    padding: 54px 20px 20px  20px;
  }
}

@media (max-width: 720px) {
  .steps {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      margin-bottom: 24px;
    }

    &__grid {
      display: none;
    }

    &__mobile {
      max-width: 320px;
      display: grid;
      justify-content: center;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 143px;
      row-gap: 39px;

      svg {

      }
    }

    &__lines {
      width: 104px;
      align-self: end;

      &:nth-child(odd) {
        justify-self: flex-end;
      }
      &:nth-child(even) {
        justify-self: flex-start;
      }
    }

    &__step {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      gap: 12px;

      img {
        width: 50px;
      }

      &:last-child {
        grid-column: 2;
      }
    }

    &__visual {
      height: 77px;
      gap: 4px;
    }

    &__num {
      font-size: 50px;
      font-weight: 700;
      line-height: 61px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
  .steps__step_top{
    width: 175px;
    height: 150px;
    background-image: none;
    border: 2px dashed #5B5774;
    border-radius: 25px;
    justify-content: center;
    background-size: 100% 100%;
    background-position: center;
    padding: 0;
  }
  .steps__step_bottom{
    justify-content: center;
    background-image: none;
    border: 2px dashed #5B5774;
    border-radius: 25px;
    background-size: 100% 100%;
    width: 175px;
    height: 150px;
    background-position: center;
    padding: 0;
  }
}
