@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.mainBlock {
  background-image: url("../../Assets/Images/MainBlock/MainWeb.webp");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 650px;
}
.mainBlock-mobTop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  justify-content: space-between;
}
.mainBlock-contact {
  display: flex;
  padding: 120px 0 48px 0;
}
.mainBlock-contact__item {
  display: flex;
  align-items: flex-start;
  margin-right: 30px;
}
.mainBlock-contact__img {
  height: 40px;
  width: 40px;
  margin: 0 10px;
  padding: 8px;
  border-radius: 30px;
  background: #FFA700;
}
.mainBlock-contact__text-title {
  color: #FFF;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.mainBlock-contact__text-text {
  color: #FFF;
  text-align: justify;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  text-decoration: none;
}
.mainBlock-title {
  color: #FFF;
  font-family: Montserrat, sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 108%;
  width: 699px;
  margin-bottom: 12px;
}
.mainBlock-text {
  overflow: hidden;
  color: #E5E5E5;
  text-align: justify;
  text-overflow: ellipsis;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 590px;
  margin-bottom: 40px;
}
.mainBlock-form {
  display: flex;
  width: 590px;
  align-items: center;
  gap: 10px;
}
.mainBlock-form__phone {
  width: 60%;
  height: 48px;
  margin: auto 0;
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--black-300, #D0D5DD);
  background: var(--bg-bg-primary, #FFF);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.react-international-phone-input, .react-international-phone-country-selector-button {
  background: none !important;
  border: none !important;
}

@media (max-width: 1440px) {
  .mainBlock {
    background-image: url("../../Assets/Images/MainBlock/MainMac.webp");
  }
}
@media (max-width: 1200px) {
  .mainBlock {
    background-image: url("../../Assets/Images/MainBlock/MainTablet.webp");
  }
}
@media (max-width: 720px) {
  .mainBlock {
    display: flex;
    align-items: flex-end;
    background-image: url("../../Assets/Images/MainBlock/MainMb.webp");
    height: 590px;
    position: relative;
  }
  .mainBlock-mobTop {
    display: flex;
    align-items: center;
    padding: 10px 10px 0;
  }
  .mainBlock-wrapper {
    padding-bottom: 60px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .mainBlock-contact {
    display: none;
  }
  .mainBlock-title {
    width: 100%;
    line-height: 108%;
    margin-top: 60px;
    padding: 0;
    font-size: 20px;
  }
  .mainBlock-text {
    text-align: start;
    width: 100%;
    font-size: 14px;
  }
  .mainBlock-form {
    width: 100%;
    flex-direction: column;
  }
  .mainBlock-form__phone {
    width: 100%;
  }
}

