@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.contact_bg {
  background: #FFF;
}

.contact_block {
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
}

.contact_block_heading {
  color: #313131;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  margin-bottom: 40px;
}

.contact {
  display: flex;
}

.contact_list_block {
  border-radius: 16px;
  border: 0.799px solid #EAECF0;
  background: #FFF;
  box-shadow: 0px 0px 15.973px 0px rgba(188, 188, 188, 0.1);
  max-width: 426px;
  display: flex;
  padding: 40px 44px 32px 44px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  margin-right: 20px;
  min-width: 378px;
}

.contact_heading {
  color: #313131;
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 32px;
}

.contact_subheading {
  color: #313131;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.contact_info {
  color: #959595;
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.contact_info_block {
  margin-left: 10px;
  margin-bottom: 32px;
}

.contact_social {
  color: #313131;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 28px;
}

.contact_social_block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icons {
  display: flex;
  justify-content: space-evenly;
  width: 128px;
}

.contact_svg_color {
  height: 40px;
  width: 40px;
  border-radius: 30px;
  background: #0A090D;
}

.contact_map {
  width: 100%;
  border-radius: 8px;
}

.social_icon:hover {
  cursor: pointer;
}
.social_icon:hover path {
  fill: color-mix(in srgb, #494949, #000 15%);
}

@media (max-width: 1200px) {
  .contact_map {
    width: 100%;
  }
}
@media (max-width: 720px) {
  .contact {
    display: flex;
    flex-direction: column;
  }
  .contact_map {
    width: 100%;
    height: 445px;
  }
  .contact_list_block {
    display: flex;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 24px;
    max-width: 100%;
    min-width: 320px;
  }
  .contact_block_heading {
    font-size: 28px;
    display: none;
  }
  .contact_heading {
    font-size: 28px;
    text-align: center;
  }
  .contact_social {
    margin-right: 0;
    margin-bottom: 28px;
  }
  .contact_social_block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

