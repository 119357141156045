@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.button {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFA700;
  color: #3C4B45;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  border: none;
}
.button-wrapper {
  width: 40%;
  height: 48px;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;
  border: 2px dashed #FFE0BD;
}
.button-wrapper:hover {
  border: 2px solid #FFE0BD;
}

@media (max-width: 720px) {
  .button-wrapper {
    margin-top: 12px;
    width: 100%;
  }
}

