@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.steps {
  padding: 60px 0;
  font-family: Montserrat;
}
.steps__mobile {
  display: none;
}
.steps__title {
  color: #3C4B45;
  font-family: Montserrat, sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
@media (max-width: 1440px) {
  .steps__title {
    line-height: 41px;
  }
}
@media (max-width: 720px) {
  .steps__title {
    font-size: 34px;
    line-height: 41px;
    text-align: center;
  }
}
.steps svg {
  width: 100%;
}
.steps svg circle {
  fill: #001219;
}
.steps svg stop {
  stop-color: #F8F8F8;
}
.steps svg stop:nth-child(3n+3) {
  stop-color: #001219;
}
.steps__grid {
  display: grid;
  grid-template-columns: 1fr;
}
.steps__top, .steps__bottom {
  padding: 32px;
  display: flex;
  gap: 171px;
}
.steps__top {
  padding-left: 100px;
}
.steps__bottom {
  padding-left: 274px;
  gap: 190px;
}
.steps__num {
  font-family: Montserrat;
  font-size: 50px;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(158, 158, 158, 0.4);
}
.steps__visual {
  justify-self: center;
  display: flex;
  gap: 5px;
  height: 77px;
}
.steps__visual img {
  align-self: end;
}
.steps__step {
  min-width: 173px;
  max-width: 227px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #3C4B45;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}
.steps__step_bottom .steps__visual {
  order: 99;
}
.steps__step_bottom .steps__visual img {
  align-self: flex-start;
}
.steps__step_bottom .steps__visual div {
  align-self: flex-end;
}

@media (max-width: 1440px) {
  .steps {
    padding: 60px 0;
    font-family: Montserrat;
  }
  .steps__title {
    color: #3C4B45;
    font-family: Montserrat, sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }
}
@media (max-width: 1440px) and (max-width: 1440px) {
  .steps__title {
    line-height: 41px;
  }
}
@media (max-width: 1440px) and (max-width: 720px) {
  .steps__title {
    font-size: 34px;
    line-height: 41px;
    text-align: center;
  }
}
@media (max-width: 1440px) {
  .steps__grid {
    display: grid;
    grid-template-columns: 1fr;
  }
  .steps__top, .steps__bottom {
    padding: 32px;
    display: flex;
    gap: 161px;
  }
  .steps__bottom {
    padding-left: 163px;
    padding-top: 26px;
    gap: 190px;
  }
  .steps__num {
    font-size: 50px;
    font-weight: 700;
    line-height: 61px;
    letter-spacing: 0em;
    text-align: center;
    text-align: center;
    color: rgba(158, 158, 158, 0.4);
  }
  .steps__visual {
    justify-self: center;
    display: flex;
    gap: 5px;
    height: 77px;
  }
  .steps__visual img {
    align-self: end;
    width: 49px;
  }
  .steps__step {
    min-width: 173px;
    max-width: 202px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #3C4B45;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }
  .steps__step_bottom .steps__visual {
    order: 99;
  }
  .steps__step_bottom .steps__visual img {
    align-self: flex-start;
  }
  .steps__step_bottom .steps__visual div {
    align-self: flex-end;
  }
}
@media (max-width: 1200px) {
  .steps {
    padding: 60px 0;
    font-family: Montserrat;
  }
  .steps__title {
    color: #3C4B45;
    font-family: Montserrat, sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-bottom: 59px;
  }
}
@media (max-width: 1200px) and (max-width: 1440px) {
  .steps__title {
    line-height: 41px;
  }
}
@media (max-width: 1200px) and (max-width: 720px) {
  .steps__title {
    font-size: 34px;
    line-height: 41px;
    text-align: center;
  }
}
@media (max-width: 1200px) {
  .steps__grid {
    display: grid;
    grid-template-columns: 1fr;
  }
  .steps__top, .steps__bottom {
    padding: 0px;
    display: flex;
    gap: 85px;
  }
  .steps__bottom {
    padding-left: 96px;
    padding-top: 0px;
    gap: 110px;
  }
  .steps__bottom .steps__step {
    min-width: auto;
    max-width: auto;
  }
  .steps__num {
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: center;
    text-align: center;
    text-align: center;
    color: rgba(158, 158, 158, 0.4);
  }
  .steps__visual {
    justify-self: center;
    display: flex;
    gap: 5px;
    height: 48px;
  }
  .steps__visual img {
    align-self: end;
    width: 30px;
  }
  .steps__step {
    min-width: 109px;
    max-width: 197px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #3C4B45;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
  .steps__step_bottom .steps__visual {
    order: 99;
  }
  .steps__step_bottom .steps__visual img {
    align-self: flex-start;
  }
  .steps__step_bottom .steps__visual div {
    align-self: flex-end;
  }
}
@media (max-width: 720px) {
  .steps {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .steps__title {
    margin-bottom: 24px;
  }
  .steps__grid {
    display: none;
  }
  .steps__mobile {
    max-width: 320px;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 143px;
    row-gap: 39px;
    -moz-column-gap: 18px;
         column-gap: 18px;
  }
  .steps__lines {
    width: 104px;
    align-self: end;
  }
  .steps__lines:nth-child(odd) {
    justify-self: flex-end;
  }
  .steps__lines:nth-child(even) {
    justify-self: flex-start;
  }
  .steps__step {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    gap: 12px;
  }
  .steps__step img {
    width: 50px;
  }
  .steps__step:last-child {
    grid-column: 2;
  }
  .steps__visual {
    height: 77px;
    gap: 4px;
  }
  .steps__num {
    font-size: 50px;
    font-weight: 700;
    line-height: 61px;
    letter-spacing: 0em;
    text-align: center;
  }
}/*# sourceMappingURL=Steps.css.map */