// імпорт шрифту
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap')

// Оголошення змінних
$primary: #0B062D
$form: #FFA700
$secondary: #FFA700
$title: #3C4B45
$text: #494949
$textHover: #959595
$background: #F8F8F8
$MainBgWeb: url('../../Assets/Images/MainBlock/MainWeb.webp')
$MainBgMac: url('../../Assets/Images/MainBlock/MainMac.webp')
$MainBgTablet: url('../../Assets/Images/MainBlock/MainTablet.webp')
$MainBgMb: url('../../Assets/Images/MainBlock/MainMb.webp')
$advantagesVertical: #DC8724
$black: #0A090D
$white: #FFF
$headerBtn: #0C111D
$headerBtnHover: #FFA700
$formBorderColor: #D0D5DD
$formPlaceholderColor: #959595
$headerLinkColor: #19191C
$borderColorMainBlockBtn: #FFE0BD
$btnTextColor: #4B4B4B
$starColor: #FCA311
// Оголошення міксину
@mixin title
  color: $title
  font-family: Montserrat, sans-serif
  font-size: 34px
  font-style: normal
  font-weight: 600
  line-height: normal
  @media (max-width: 1440px)
    line-height: 41px
  @media (max-width: 720px)
    font-size: 34px
    line-height: 41px
    text-align: center

  
