@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.footer_block {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #F8F8F8;
}

.footer_link {
  text-decoration: none;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.footer_logo {
  width: 100%;
  height: auto;
  max-width: 123px;
  margin-bottom: 24px;
}

.footer_info_company {
  color: #959595;
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  max-width: 270px;
}

.footer_heading_menu {
  color: #0B062D;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.footer_menu_link {
  color: var(--primary, var(--4B4B4B, #4B4B4B));
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
  cursor: pointer;
}

.footer_contact {
  max-width: 277px;
}

.footer_heading_contact {
  color: #0B062D;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.footer_subheading_contact {
  color: var(--primary, var(--4B4B4B, #4B4B4B));
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footer_info_contact {
  color: #9F9F9F;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 12px;
}

@media (max-width: 1200px) {
  .footer_logo {
    width: 100%;
    height: auto;
    max-width: 169px;
    margin-bottom: 24px;
  }
  .footer_info_company {
    color: #959595;
    text-align: justify;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    max-width: 169px;
  }
}
@media (max-width: 720px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer_block_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .footer_logo {
    width: 100%;
    max-width: 140px;
  }
  .footer_info_company {
    max-width: 290px;
    text-align: left;
  }
  .footer_heading_contact {
    text-align: center;
  }
  .footer_company {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .footer_menu {
    margin-bottom: 30px;
  }
  .footer_menu_link {
    color: var(--primary, var(--4B4B4B, #4B4B4B));
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
  }
  .footer_contact {
    max-width: 270px;
  }
  .footer_info_contact {
    text-align: left;
  }
  .footer_heading_contact {
    font-size: 22px;
  }
}

