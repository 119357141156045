@import '../../Constants/Variables.sass'


.thank_block
  display: flex
  justify-content: center
  height: 80vh
  align-items: center
  flex-direction: column

.thank_text
  color: var(--Gray-900, #101828)
  font-family: Montserrat, sans-serif
  font-size: 60px
  font-style: normal
  font-weight: 600
  line-height: 72px
  letter-spacing: -1.2px
  margin-bottom: 30px

.btn_thank
  display: flex
  height: 44px
  padding: 8px 16px
  justify-content: center
  align-items: center
  gap: 8px
  border-radius: 8px
  background: $primary
  border: none
  color: inherit

.btn_thank_text
  color: var(--text-text-primary_on-brand, #FFF)
  text-align: center
  font-family: Montserrat, sans-serif
  font-size: 21px
  font-style: normal
  font-weight: 600
  line-height: 24px
  text-decoration: none


@media (max-width: 720px)
  .thank_text
    color: var(--Gray-900, #101828)
    font-family: Montserrat, sans-serif
    font-size: 30px
    font-style: normal
    font-weight: 600
    line-height: 50px
    letter-spacing: -1.2px
    margin-bottom: 10px

  .btn_thank_text
    font-size: 17px
