@import '../../Constants/Variables.sass';

.aboutUs {
  font-family: Montserrat;

  &__container {
    background-color: $background;
    padding: 80px 0;
  }

  display: grid;
  grid-template-columns: 560px auto;
  gap: 31px;

  &__imgs {
    $gap: 10px;
    display: grid;
    grid-template-columns: 0.8fr 1.27fr;
    gap: $gap;

    img {
      width: 100%;
    }

    &_left{
      display: grid;
      grid-template-columns: 1fr;
      gap: $gap;
    }

    &_right {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: stretch;
      gap: $gap;


      //img:first-child {
      //  grid-column: 1/3;
      //  height: 150px;
      //}
    }

  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    @include title;
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

    color: #959595;;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__stars {
    margin-bottom: 4px;
    display: flex;
    gap: 4px;
  }

  &__star {
    path {
      fill: rgba($starColor, .5);
    }

    &_active {
      path {
        fill: #FF9A00;
      }
    }
  }

  &__ratingText {
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;

    color: $title;
  }

  &__socials {
    display: flex;
    gap: 28px;
    path {
      fill: $text;
    }

    svg:hover {
      cursor: pointer;
      path {
        fill: color-mix(in srgb, $text, #000 15%);
      }
    }
  }
}

@media (max-width: 1440px) {
  .aboutUs {
  
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 31px;
  
    &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      
  
      color: #959595;
    }
  }
}

@media (max-width: 1200px) {
  .aboutUs {
    font-family: Montserrat;
  
    &__container {
      background-color: $background;
      padding: 80px 0;
    }
  
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
  
    &__imgs {
      width: 100%;
      $gap: 10px;
      display: grid;
      grid-template-columns: 0.8fr 1.29fr;
      gap: $gap;

      img {
        width: 100%;
      }
  
      &_left{
        display: grid;
        grid-template-columns: 1fr;
        gap: $gap;
      }
  
      &_right {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $gap;
  
  
        //img:first-child {
        //  grid-column: 1/3;
        //}
      }
  
    }
  
    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  
    &__title {
      @include title;
      margin-bottom: 16px;
    }
  
    &__text {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
  
      color: #959595;
      margin-bottom: 24px;
    }
  
    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  
    &__stars {
      margin-bottom: 4px;
      display: flex;
      gap: 4px;
    }
  
    &__star {
      path {
        fill: rgba($starColor, .5);
      }
  
      &_active {
        path {
          fill: $starColor;
        }
      }
    }
  
    &__ratingText {
      font-size: 22px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
  
      color: $title;
    }
  
    &__socials {
      display: flex;
      gap: 28px;
      path {
        fill: $text;
      }
  
      svg:hover {
        cursor: pointer;
        path {
          fill: color-mix(in srgb, $text, #000 15%);
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .aboutUs {
    font-family: Montserrat;
  
    &__container {
      background-color: $white;
      padding: 40px 0;
    }
  
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  
    &__imgs {
      width: 100%;
      $gap: 16px;
      display: grid;
      grid-template-columns: 1fr;
      gap: $gap;
  
      img {
        width: 100%;
      }
  
      &_left{
        display: grid;
        grid-template-columns: 1fr;
        gap: $gap;
      }
  
      &_right {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $gap;
  
  
        //img:first-child {
        //  grid-column: 1/3;
        //}
      }
  
    }
  
    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      grid-row: 1; 
    }
  
    &__title {
      @include title;
      margin-bottom: 16px;

      text-align: center;
    }
  
    &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
  
      color: #959595;
      margin-bottom: 24px;
    }
  
    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  
    &__stars {
      margin-bottom: 4px;
      display: flex;
      gap: 4px;
    }
  
    &__star {
      path {
        fill: rgba($starColor, .5);
      }
  
      &_active {
        path {
          fill: $starColor;
        }
      }
    }
  
    &__ratingText {
      font-size: 22px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
  
      color: $title;
    }
  
    &__socials {
      display: flex;
      gap: 28px;
      path {
        fill: $text;
      }
  
      svg:hover {
        cursor: pointer;
        path {
          fill: color-mix(in srgb, $text, #000 15%);
        }
      }
    }
  }
}
