@import "../../Constants/Variables.sass"
.mainBlock
  background-image: $MainBgWeb
  background-position: center
  background-size: cover

  width: 100%
  height: 650px
  &-mobTop
    position: absolute
    top: 0
    left: 0
    width: 100%
    display: none
    justify-content: space-between
  &-contact
    display: flex
    padding: 120px 0 48px 0
    &__item
      display: flex
      align-items: flex-start
      margin-right: 30px
    &__img
      height: 40px
      width: 40px
      margin: 0 10px
      padding: 8px
      border-radius: 30px
      background: $secondary
    &__text
      &-title
          color: #FFF
          font-family: Montserrat, sans-serif
          font-size: 20px
          font-style: normal
          font-weight: 500
          line-height: normal
          margin: 0
      &-text
        color: #FFF
        text-align: justify
        font-family: Montserrat, sans-serif
        font-size: 16px
        font-style: normal
        font-weight: 400
        line-height: 24px
        margin: 0
        text-decoration: none
  &-title
    color: #FFF
    font-family: Montserrat, sans-serif
    font-size: 44px
    font-style: normal
    font-weight: 600
    line-height: 108%
    width: 699px
    margin-bottom: 12px
  &-text
    overflow: hidden
    color: #E5E5E5
    text-align: justify
    text-overflow: ellipsis
    font-family: Montserrat, sans-serif
    font-size: 18px
    font-style: normal
    font-weight: 300
    line-height: normal
    width: 590px
    margin-bottom: 40px
  &-form
    display: flex
    width: 590px
    //justify-content: space-between
    align-items: center
    gap: 10px
    &__phone
      width: 60%
      height: 48px
      margin: auto 0
      display: flex
      padding: 10px 12px
      align-items: center
      gap: 8px
      align-self: stretch
      border-radius: 8px
      border: 1px solid var(--black-300, #D0D5DD)
      background: var(--bg-bg-primary, #FFF)
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05)
.react-international-phone-input,.react-international-phone-country-selector-button
  background: none!important
  border: none !important
@media (max-width: 1440px)
  .mainBlock
    background-image: $MainBgMac
@media (max-width: 1200px)
  .mainBlock
    background-image: $MainBgTablet
@media (max-width: 720px)
  .mainBlock
    display: flex
    align-items: flex-end
    background-image: $MainBgMb
    height: 590px
    &-mobTop
      display: flex
    &-wrapper
      padding-bottom: 60px
      align-items: center
      display: flex
      flex-direction: column
    &-contact
      display: none
    &-title
      width: 100%
      line-height: 108%
      margin-top: 60px
      padding: 0
      font-size: 20px
    &-text
      text-align: start
      width: 100%
      font-size: 14px
    &-form
      width: 100%
      flex-direction: column
      &__phone
        width: 100%
