@import "../../Constants/Variables"
.wrap
  background-color: $form
  background-size: contain
  background-repeat: no-repeat
  &_black
    background: #001219

.box
  padding: 64px 0
  display: flex
  flex-direction: column
  &_subTitle
    overflow: hidden
    color: $black
    text-overflow: ellipsis
    font-family: 'Montserrat', sans-serif
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: normal
    margin-bottom: 20px
  &_title
    color: $black
    font-family: 'Montserrat', sans-serif
    font-size: 34px
    font-style: normal
    font-weight: 600
    line-height: normal
    margin-bottom: 4px
    &__white
      color: $white


  &_form
    display: flex
    justify-content: space-between
    align-items: flex-end
    gap: 12px
    &__box
      display: flex
      flex-direction: column
      width: 39%
      position: relative
      &_lbl
        color: $black
        font-family: 'Montserrat', sans-serif
        font-size: 14px
        font-style: normal
        font-weight: 500
        line-height: normal
        margin-bottom: 6px
        &__white
          color: $white
      &_inp
        border-radius: var(--radius-md, 8px)
        border: 1px solid $formBorderColor
        background: $white
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05)
        height: 48px
        box-sizing: border-box
        padding: 10px var(--spacing-lg, 12px)
        outline: none
        display: flex
        align-items: center
        font-family: 'Gilroy', sans-serif
        font-size: 14px
        &::placeholder
          color: $formPlaceholderColor
          font-family: 'Gilroy', sans-serif
          font-size: 14px
          font-style: normal
          font-weight: 400
          line-height: 24px
        & > :nth-child(2)
          font-family: 'Gilroy', sans-serif!important
          font-size: 14px!important
          font-style: normal!important
          font-weight: 400!important
          line-height: 24px!important
          width: 100%!important
    &__btnBox
      border-radius: 10px
      border: 2px dashed #FFE0BD
      box-sizing: border-box
      padding: 4px
      height: 48px
      width: 180px
      display: flex
      align-items: center
      &:hover
        border: 2px solid  #FFE0BD
      &_second
        border: 2px dashed #FFE0BD
        &:hover
          border: 2px solid #FFE0BD
      &_btn
        width: 100%
        height: 100%
        box-sizing: border-box
        border-radius: 8px
        background: $black
        border: none
        color:  #FF9A00
        text-align: center
        font-family: 'Montserrat', sans-serif
        font-size: 14px
        font-style: normal
        font-weight: 600
        line-height: normal
        &__second
          background: #FFA700
          color: $black


@media (max-width: 1200px)
  .box
    &_form
      &__box
        display: flex
        flex-direction: column
        width: 32%
        &_lbl
          font-family: 'Montserrat', sans-serif
          font-size: 14px
          font-style: normal
          font-weight: 500
          line-height: normal
          margin-bottom: 6px
        &_inp
          border-radius: var(--radius-md, 8px)
          box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05)
          height: 48px
          box-sizing: border-box
          padding: 10px var(--spacing-lg, 12px)
          &::placeholder
            font-family: 'Gilroy', sans-serif
            font-size: 14px
            font-style: normal
            font-weight: 400
            line-height: 24px
      &__btnBox
        border-radius: 10px
        box-sizing: border-box
        padding: 4px
        height: 48px
        width: 237px
        display: flex
        align-items: center
        &_btn
          width: 100%
          height: 100%
          border-radius: 8px
          border: none
          text-align: center
          font-family: 'Montserrat', sans-serif
          font-size: 14px
          font-style: normal
          font-weight: 600
          line-height: normal


@media (max-width: 720px)
  .box
    text-align: center
    &_form
      flex-direction: column
      align-items: stretch
      gap: 21px
      &__box
        display: flex
        flex-direction: column
        align-items: flex-start
        width: 100%
        &_lbl
          font-family: 'Montserrat', sans-serif
          font-size: 14px
          font-style: normal
          font-weight: 500
          line-height: normal
          margin-bottom: 6px
        &_inp
          width: 100%
          border-radius: var(--radius-md, 8px)
          box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05)
          height: 44px
          box-sizing: border-box
          padding: 10px var(--spacing-lg, 12px)
          &::placeholder
            font-family: 'Gilroy', sans-serif
            font-size: 14px
            font-style: normal
            font-weight: 400
            line-height: 24px
      &__btnBox
        margin-top: 10px
        border-radius: 10px
        box-sizing: border-box
        padding: 4px
        height: 48px
        width: 100%
        display: flex
        align-items: center
        &_btn
          width: 100%
          height: 100%
          border-radius: 8px
          border: none
          text-align: center
          font-family: 'Montserrat', sans-serif
          font-size: 14px
          font-style: normal
          font-weight: 600
          line-height: normal

