@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.wrapHidden {
  animation: slideOut 0.4s ease-out;
  transform: translateY(-100%);
}

.wrapShow {
  animation: slideIn 0.4s ease-in;
}

.wrap {
  position: sticky;
  top: 0;
  background: #FFF;
  z-index: 1000;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}
.container_logo {
  width: 88.137px;
}
.container_linksWrap {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 44px;
}
.container_linksWrap__link {
  color: #19191C;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.container_btn {
  display: flex;
  height: 40px;
  padding: 14px 28px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  background: #0C111D;
  border: none;
}
.container_btn:hover {
  background: #FFA700;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
@media (max-width: 1200px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px 0;
  }
  .container_logo {
    width: 98.137px;
  }
  .container_linksWrap {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 44px;
  }
  .container_linksWrap__link {
    color: #19191C;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
  }
  .container_btn {
    display: none;
  }
}
@media (max-width: 720px) {
  .container {
    display: none;
  }
}

