@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.advantages {
  background: #F8F8F8;
  padding: 40px 0;
}
.advantages-wrapper {
  display: flex;
  gap: 210px;
}
.advantages-item {
  position: relative;
}
.advantages-item__vertical {
  position: absolute;
  z-index: 1;
  right: -45%;
  bottom: -50%;
  height: 123px;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #DC8724 50%, rgba(217, 217, 217, 0) 100%);
}
.advantages-item__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.advantages-item__img {
  width: 46px;
  height: 44px;
  margin-right: 20px;
}
.advantages-item__title {
  width: 70%;
  color: #0A090D;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: auto 0;
}
.advantages-item__text {
  color: #494949;
  width: 300px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (max-width: 1440px) {
  .advantages-wrapper {
    display: flex;
    gap: 130px;
  }
  .advantages-item__vertical {
    right: -20%;
  }
}
@media (max-width: 1200px) {
  .advantages-wrapper {
    gap: 0px;
  }
  .advantages-item__img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .advantages-item__title {
    font-size: 16px;
  }
  .advantages-item__wrapper {
    margin-bottom: 6px;
  }
  .advantages-item__text {
    width: 225px;
    font-size: 10px;
  }
  .advantages-item__vertical {
    right: 7%;
    bottom: -65%;
  }
}
@media (max-width: 720px) {
  .advantages {
    padding: 0;
  }
  .advantages-wrapper {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    gap: 0;
  }
  .advantages-item {
    margin: 50px 0;
  }
  .advantages-item__img {
    margin-right: 16px;
    width: 46px;
    height: 43px;
  }
  .advantages-item__title {
    font-size: 20px;
  }
  .advantages-item__wrapper {
    margin-bottom: 18px;
  }
  .advantages-item__text {
    width: 300px;
    font-size: 14px;
  }
  .advantages-item__vertical {
    transform: rotate(90deg);
    bottom: -300%;
    right: 50%;
    height: 320px;
  }
}

