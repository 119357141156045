@import "../../Constants/Variables"
.advantages
  background: #F8F8F8
  padding: 40px 0
  &-wrapper
    display: flex
    gap: 210px
  &-item
    position: relative
    &__vertical
      position: absolute
      z-index: 1
      right: -45%
      bottom: -50%
      height: 123px
      border: 1px solid
      border-image-slice: 1
      border-image-source: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%,  $advantagesVertical 50%, rgba(217, 217, 217, 0.00) 100%)
    &__wrapper
      display: flex
      align-items: center
      margin-bottom: 12px

    &__img
      width: 46px
      height: 44px
      margin-right: 20px
    &__title
      width: 70%
      color: $black
      font-family: Montserrat,sans-serif
      font-size: 20px
      font-style: normal
      font-weight: 500
      line-height: normal
      margin: auto 0
    &__text
      color: $text
      width: 300px
      font-family: Montserrat, sans-serif
      font-size: 14px
      font-style: normal
      font-weight: 500
      line-height: normal
@media(max-width: 1440px)
  .advantages-wrapper
    display: flex
    gap: 130px

  .advantages-item__vertical
    right: -20%
@media (max-width: 1200px)

  .advantages-wrapper
    gap: 0px
  .advantages
    &-item
      &__img
        width: 30px
        height: 30px
        margin-right: 10px
      &__title
        font-size: 16px
      &__wrapper
        margin-bottom: 6px
      &__text
        width: 225px
        font-size: 10px
      &__vertical
        right: 7%
        bottom: -65%
@media (max-width: 720px)
  .advantages
    padding: 0
    &-wrapper
      flex-direction: column
      align-items: flex-start
      display: flex
      gap: 0
    &-item
      margin: 50px 0
      &__img
        margin-right: 16px
        width: 46px
        height: 43px
      &__title
        font-size: 20px
      &__wrapper
        margin-bottom: 18px
      &__text
        width: 300px
        font-size: 14px
      &__vertical
        transform: rotate(90deg)
        bottom: -300%
        right: 50%
        height: 320px
