.specOffer
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  padding: 40px
  background-image: url("../../Assets/Images/offerForm.webp")
  height: 325px
  background-size: cover
  background-position: center
  position: relative
  margin: 40px auto
  &_img
    position: absolute
    top: 21px
    left: 21px
    stroke-width: 1px
    stroke: #FFF
    backdrop-filter: blur(13px)
    z-index: 1
    border-radius: 14px
    &_mb
      display: none
  &_title
    color: var(--Primary-primary, #233140)
    font-family: Montserrat, sans-serif
    font-size: 36px
    font-style: normal
    font-weight: 600
    line-height: normal
    z-index: 2
    margin-bottom: 0
  &_text
    color: var(--Primary-primary, #233140)
    font-family: Montserrat, sans-serif
    font-size: 16px
    font-style: normal
    font-weight: 500
    line-height: normal
    letter-spacing: 0.32px
    margin-bottom: 28px
    z-index: 2
    &__green
      color: var(--PrimaryBrand-primary-brand, #4D9559)
      font-family: Montserrat, sans-serif
      font-size: 20px
      font-style: normal
      font-weight: 700
      line-height: normal
      letter-spacing: 0.4px
  &_wrap
    display: flex
    z-index: 2

    &__card
      color: var(--Ink-Dark, #252A31)
      font-family: Montserrat, sans-serif
      font-size: 14px
      font-style: normal
      font-weight: 500
      line-height: normal
      letter-spacing: 0.28px
      padding: 8px 12px
      border-radius: 15px
      border: 1px solid var(--additional-white-hover, #F1F4F7)
      background: var(--White, #FFF)
      margin: 0 12px 24px 0
  &_button
    color: var(--text-text-primary_on-brand, #FFF)
    text-align: center
    font-family: Montserrat, sans-serif
    font-size: 15px
    font-style: normal
    font-weight: 600
    line-height: 24px
    justify-content: center
    align-items: center
    gap: 8px
    border-radius: 10px
    background: var(--bg-bg-primary-inverse, #0C111D)
    border: none
    padding: 8px 23px
    text-decoration: none
    cursor: pointer

@media (max-width: 1200px)
  .specOffer
    border-radius: 26px
    border: 1px solid rgba(255, 167, 0, 0.40)
    &_img
      top: 29px
      border-radius: 20px 52px 10px 20px
      width: 668px
      height: 164px
      clip-path: polygon(0 0, calc(100% - 32px) 0, 100% 145px, 100% 100%, calc(100%) 100%, 0px 100%, 0 calc(100%), 0 20px)
    &_title
      font-size: 33px
    &_text
      font-size: 14px
      margin-bottom: 28px
      &__green
        font-size: 16px
    &_wrap
      &__card
        font-size: 12px
        padding: 8px 12px
    &_button
      font-size: 15px
@media (max-width: 720px)
  .specOffer
    padding: 20px 12px
    height: 333px
    &_img
      display: none
      &_mb
        top: 12px
        left: 10px
        display: flex
        position: absolute
        width: 306px
        height: 154px

    &_title
      font-size: 20px
      margin: 4px 10px
      width: 280px
      letter-spacing: 0.28px
    &_text
      margin: 0 auto 18px 10px
      width: 280px

    &_wrap
      flex-direction: column
      margin-bottom: 12px
      &__card
        margin: 0 auto 8px 0
        font-size: 9px
