.vacancies {
  padding: 60px 0;
  font-family: Montserrat;
  &__title {
    font-size: 34px;
    font-weight: 600;
    line-height: 41.45px;
    text-align: center;

    margin-bottom: 40px;
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;
    row-gap: 28px;
  }
}

.vacanciesCard {
  height: 680px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 209px auto;

  border: 0.8px solid #e8e8e9;
  border-radius: 8px;

  &__top {
    bottom: -0%;
    position: relative;
  }

  &__country {
    position: absolute;
    top: 8px;
    left: 8px;

    display: flex;
    gap: 6px;
    background: #f9fafb;
    padding: 4px 6px;

    border: 1px solid #eaecf0;
    border-radius: 8px;

    &_title {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
      color: #313131;
    }
  }

  &__greenBadge {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 4px 8px;

    background-color: #eaffea;
    border: 1px solid #9ee89e;
    border-radius: 8px;

    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: #228c22;
  }

  &__photo {
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__bottom {
    padding: 20px 14px;
    display: grid;
    grid-template-columns: 1fr;
    //grid-template-rows: 319px auto;
  }

  &__text {
    border-bottom: 0.8px solid #e8e8e9;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    text-align: center;
    color: #101828;
    margin-bottom: 8px;
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.07px;
    text-align: left;
    color: #959595;
  }

  &__interactive {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  &__price {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: -0.02em;
    color: #4d9559;
    text-align: center;
    margin-bottom: 20px;

    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.5px;
      letter-spacing: -0.02em;
      text-align: left;
      color: #aaadb9;
    }
  }

  a {
    all: unset;
  }

  &__btn {
    all: unset;
    width: 350px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px #1018280d;
    border-radius: 10px;

    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    font-size: 13.58px;
    font-weight: 600;
    line-height: 19.17px;
    color: #101828;
    padding: 12px 0;
    transition: 0.1s;
    &:hover {
      background-color: #101828;
      color: white;

      svg {
        path {
          fill: white;
        }
      }
    }
  }
}

.vacancies_ul{
  padding-left: 0;
}

.vacancies_li{
  margin-left: 10px;
}

@media (max-width: 1440px) {
  .vacanciesCard {
    height: 680px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 209px auto;

    border: 0.8px solid #e8e8e9;
    border-radius: 8px;

    &__top {
      position: relative;
    }

    &__country {
      position: absolute;
      top: 8px;
      left: 8px;

      display: flex;
      gap: 6px;
      background: #f9fafb;
      padding: 4px 6px;

      border: 1px solid #eaecf0;
      border-radius: 8px;

      &_title {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        color: #313131;
      }
    }

    &__greenBadge {
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 4px 8px;

      background-color: #eaffea;
      border: 1px solid #9ee89e;
      border-radius: 8px;

      font-size: 15px;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
      color: #228c22;
    }

    &__photo {
      width: 100%;
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &__bottom {
      padding: 20px 14px;
      display: grid;
      grid-template-columns: 1fr;
      //grid-template-rows: 279px auto;
      padding-top: 0;
    }

    &__text {
      border-bottom: 0.8px solid #e8e8e9;
    }

    &__title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.38px;
      text-align: center;
      color: #101828;
      margin-bottom: 8px;
    }

    &__description {
      font-size: 14px;
      font-weight: 400;
      line-height: 18.07px;
      text-align: left;
      color: #959595;
    }

    &__interactive {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__price {
      font-size: 24px;
      font-weight: 600;
      line-height: 29.26px;
      letter-spacing: -0.02em;
      color: #4d9559;
      text-align: center;
      margin-bottom: 20px;

      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 19.5px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #aaadb9;
      }
    }

    a {
      all: unset;
    }

    &__btn {
      all: unset;
      width: 325px;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px #1018280d;
      border-radius: 10px;

      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;

      font-size: 13.58px;
      font-weight: 600;
      line-height: 19.17px;
      color: #101828;
      padding: 12px 0;
      transition: 0.1s;
      &:hover {
        background-color: #101828;
        color: white;

        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .vacancies {
    &__title {
      font-size: 34px;
      font-weight: 600;
      line-height: 41.45px;
      text-align: center;
    }

    &__cards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;
      row-gap: 28px;
      align-items: center;
      justify-content: center;
    }
  }

  .vacanciesCard {
    height: 729px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 209px auto;

    border: 0.8px solid #e8e8e9;
    border-radius: 8px;

    &__top {
      position: relative;
    }

    &__photo {
      height: 100%;
    }

    &__bottom {
      padding: 18px 14px;
      display: grid;
      grid-template-columns: 1fr;
      //grid-template-rows: 145px auto;
    }

    &__description {
      font-size: 14px;
      font-weight: 400;
      line-height: 17.07px;
      text-align: left;
      color: #959595;
    }

    &__interactive {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (max-width: 720px) {
  .vacancies {
    padding: 20px 0;
    &__title {
      font-size: 24px;
      font-weight: 600;
      line-height: 29.26px;
      text-align: center;
    }

    &__cards {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }

  .vacanciesCard {
    height: 700px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 209px auto;

    border: 0.8px solid #e8e8e9;
    border-radius: 8px;

    &__top {
      position: relative;
    }

    &__photo {
      height: 100%;
    }

    &__title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.38px;
      text-align: center;
    }

    &__bottom {
      padding: 12px 14px;
      display: grid;
      grid-template-columns: 1fr;
      //grid-template-rows: 142px auto;
    }

    &__description {
      font-size: 14px;
      font-weight: 400;
      line-height: 17.07px;
      text-align: left;
    }

    &__interactive {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 10px;
    }

    &__price {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.38px;
      letter-spacing: -0.02em;
      text-align: left;

      margin-bottom: 16px;

      span {
        font-size: 15px;
        font-weight: 600;
        line-height: 18.29px;
        letter-spacing: -0.02em;
        text-align: left;
      }
    }

    &__btn {
      all: unset;
      width: 292px;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px #1018280d;
      border-radius: 10px;

      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;

      font-size: 13.58px;
      font-weight: 600;
      line-height: 19.17px;
      text-align: left;
      padding: 12px 0;
      transition: 0.1s;

      &:hover {
        background-color: #101828;
        color: white;

        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
}
