.newsteps {
  padding-top: 100px;
  padding-bottom: 100px;
}

.newsteps_cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 40px;
}

.newsteps_card_bg {
  display: flex;
  width: 100%;
  height: auto;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background-image: url("../../Assets/Icons/Steps/card_bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.newsteps_icon {
  width: 72px;
  height: 72px;
  padding: 0;
  margin: 0;
}

.newsteps_text {
  color: #232323;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 1440px) {
  .newsteps_card_bg {
    width: 100%;
    padding: 32px;
    background-image: url("../../Assets/Icons/Steps/card_bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
@media (max-width: 1200px) {
  .newsteps_card_bg {
    width: 268px;
    padding: 25px;
  }
  .newsteps_cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 40px;
    flex-wrap: wrap;
    row-gap: 40px;
  }
}
@media (max-width: 720px) {
  .newsteps {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .newsteps_card_bg {
    width: 87%;
    padding: 25px;
  }
  .newsteps_cards {
    row-gap: 20px;
  }
  .newsteps_icon {
    width: 62px;
    height: 82px;
    padding: 0;
    margin: 0;
  }
}
@media (max-width: 390px) {
  .newsteps_card_bg {
    width: 95%;
    padding: 25px;
  }
  .newsteps_icon {
    width: 62px;
    height: 82px;
    padding: 0;
    margin: 0;
  }
}

